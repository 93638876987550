import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import MoreVert from "@mui/icons-material/MoreVert";

import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import { ArrowDropDown } from "@mui/icons-material";

export default function PositionedMenu({ MenuOptions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (

    <StyledEngineProvider>
      <CssVarsProvider>
        <IconButton
          id="positioned-demo-button"
          aria-controls={open ? "positioned-demo-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          color="neutral"
          onClick={(e) => {
            handleClick(e);
          }}
        >
          Consultar
          <ArrowDropDown/>
        </IconButton>
        {
          anchorEl && 
          <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
              <Menu
                  id="positioned-demo-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="positioned-demo-button"
                  placement="bottom-end"
                >
                  {
                    MenuOptions.length >0 && MenuOptions.map(option=>{
                      return(
                        <MenuItem key={option.Name} onClick={()=>{ handleClose(); option.Callback(); }}>
                           {option.Icon && 
                             <ListItemDecorator sx={{ color: "inherit" }}>
                               {option.Icon}
                             </ListItemDecorator>
                           }
                          {option.Name}
                        </MenuItem>
                      )
                    })
                  }
                </Menu>
          </div>
        }
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}
