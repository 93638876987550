import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { getDateFromString } from "../../utils/utils.js";
import { getCitasPeriodo_request } from "../../services/citas_services";

const QUERY_KEY = ['Citas'];
const fetchInterval = 5; // Minutos

const fetchData = async(fechaInicial, fechaFinal) => {
    let startMonth = dayjs(fechaInicial).format('YYYY-MM-D');
    let endMonth = dayjs(fechaFinal).format('YYYY-MM-D');

    let response = await getCitasPeriodo_request(startMonth, endMonth);
    let citas = [];
    if(response.ok){
      response.data.map(cita => {
        let fecha = getDateFromString(cita.FechaCita, cita.HoraCita);
    //     let horaCita = `${String(new Date(fecha[0]).getHours()).padStart(2, '0')}:${String(new Date(fecha[0]).getMinutes()).padStart(2, '0')}`;

        citas.push({
            title: cita.Paciente.replace('null', ''),
            start: new Date(fecha[0]),
            end: new Date(fecha[1]),
            id: cita.IdCita,
            status: cita.Status,
            idContacto: cita.IdContacto
        });
      });
    }

    return citas;
}

const useCitasData = () => {
    const { fechaInicio, fechaFinal } = useSelector((state) => state.appointments);
    
    return useQuery({
        queryFn: ()=> fetchData(fechaInicio, fechaFinal), 
        queryKey: QUERY_KEY,
        refetchInterval: (fetchInterval * 60000),
    })
};

export default useCitasData;