import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Pagination} from "antd";

import "../comp-styles/ExpedienteCard.scss";
import { Avatar } from "antd";
import Imag from "../../Img/no-image.png";
import useExpedientesData from "../../hooks/Query/useExpedientesData";

export default function ExpedientesCard({ expedientes }) {
  const ExpedientesData = useExpedientesData();
  const [itemsPerPage, setItemsPerPage] = useState(25);//25
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const navigate = useNavigate();

  const changeOffsets = (page) =>{
    let start = (page * itemsPerPage);
    let end = (start + itemsPerPage);

    setStartIndex(start);
    setEndIndex(end);
    setCurrentPage(page +1);
  }

  useEffect(()=>{
    if(expedientes.length !== ExpedientesData.data.length){
      changeOffsets(0);
    }
  }, [expedientes]);

  return (
    <>
     <Pagination total={expedientes.length} current={currentPage} pageSize={itemsPerPage} onChange={(page)=>changeOffsets(page-1)} 
      showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} expedientes`} hideOnSinglePage showSizeChanger={false}
      onShowSizeChange={()=>console.log('cambio!')}/>
      {expedientes.slice(startIndex, endIndex).map((expe) => (
        <div key={`card_${expe.idContacto}`}>
          <div className="PrinCardEx" style={{ margin: ".5% 0" }}>
            <div className="ContainerEx">
              <div
                className="CardEx"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/app/ExpedientesNuevoDetalle",
                      {
                        state: {
                          id: expe.idContacto,
                          imagen: expe.Imagen,
                          nombre: expe.Nombre,
                        },
                      });
                }}
              >
                  <Avatar size={45} 
                  style={{background:"#d7d7d7"}}
                  src={
                    <img 
                    id={`Img_ExpeCARD${expe.idContacto}`} 
                    onError={() => {
                      let img = document.getElementById(`Img_ExpeCARD${expe.idContacto}`);
                      img.setAttribute("src", Imag);
                    }}
                    src={
                    !!expe.Imagen
                      ? "https://" + expe.Imagen
                      : Imag
                  } alt="" />
                  } />
                <div style={{width:"80%"}}>
                  <b>{expe.Nombre}</b>
                  <p className="Dia">
                    <span>
                      <b>Última actualización:&nbsp;</b>
                      &nbsp;<span>{expe.Fecha}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
