import React, { useEffect, useState } from "react";
import { UseModal } from "../../../hooks/UseModal";

import "../styles/ExpedienteNuevoDetalle.scss";
import "../styles/RecetasExpedientes.scss";

import Iconos from "../../IconosDeSitio";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Firma from "../../../Img/Iconos/Palomita.svg";
import Eliminar from "../../../Img/Iconos/eliminar.svg";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import InputAntd from "../../../componentes/GeneralComponents/InputAntd";
import {
  Button,
  InputNumber,
  QRCode,
  Space,
  Row,
  Select,
  Checkbox,
  Modal,
  Empty,
} from "antd";
import RecetaTabla from "./ExpTablaRecetas";
import TextArea from "antd/es/input/TextArea";
import RecetasCard from "./RecetasCard";
import Autosuggest from "react-autosuggest";
import { Label, LeyendaError } from "../../GeneralComponents/Formularios";

// Impotando componente Modal
import ModalRecetasDetalle from "../../../componentes/GeneralComponents/ModalDetalle";
import ModalNuevaReceta from "../../../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../../../componentes/GeneralComponents/ModalFiltro";
import SelectMedicos from "../../../componentes/GeneralComponents/lista_medicos_reenviar";

import {
  getDetailRecetas_request,
  addReceta_request,
  getRecetasFilter_request,
  sendRecetas_request,
} from "../../../services/recetas_services";
import { getMedicamentos_request, getCIIEDiagnosticos_request } from "../../../services/consultas_services";

import LoaderModals from "../../GeneralComponents/Loader_modals";
import { Content, Header } from "antd/es/layout/layout";
import Campos from "../../GeneralComponents/campos";
import TextAreaAntd from "../../GeneralComponents/TextAreaAntd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import ButtonAntd from "../../GeneralComponents/ButtonAntd";
import { Add } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PDFDocument, rgb } from 'pdf-lib';
import { useSelector } from "react-redux";

function RecetasExpedientes({
  DatosContacto,
  Recetas,
  AccionesModal,
  AccionesMensaje,
  getHistoria,
}) {

  const data = useSelector((state) => state.userInfo.profesiones);
  let CedulasMedico = [];
  data.forEach(cedula => {
    CedulasMedico.push({ label: `${cedula.Cedula} - ${cedula.Profesion}`, value: cedula.Cedula })
  });

  // UseStates de los modales
  const [
    isOpenModalResetasDetalle,
    OpenModalResetasDetalle,
    CloseModalResetasDetalle,
  ] = UseModal(false);

  const [isOpenFiltro, OpenFiltro, CloseFiltro] = UseModal(false);

  // Tamaños de los modales
  const ResetasDetalleModal = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  // constgantes del select de yardiel
  const [MedicosList, setMedicosList] = useState([]);
  const [IdMedico, setIdMedico] = useState("");

  const [MedcSelection, setMedcSelection] = useState({});
  const [MedcSelectionDos, setMedcSelectionDos] = useState({});

  const [TipoReceta, setTipoReceta] = useState(null);
  const [Cedula, setCedula] = useState(CedulasMedico.length > 0 ? CedulasMedico[0].value : "");
  const [CostoReceta, setCostoReceta] = useState(0);
  const [Recomendaciones, setRecomendaciones] = useState("");
  const [Indicaciones, setIndicaciones] = useState("");

  // Variables de medicamentos
  const [Medicamento, setMedicamento] = useState("");
  const [Dosis, setDosis] = useState("");
  const [Cantidad, setCantidad] = useState(1);

  // Aray de medicamentos
  const [ListaMedicamentos, setListaMedicamentos] = useState([]);

  const [Medicamentos, setMedicamentos] = useState([]);
  const [MedicamentosFilter, setMedicamentosFilter] = useState([]);

  const [loader, setLoader] = useState(false);

  const [loaderDetalle, setLoaderDetalle] = useState(false);

  const [DetailRecetas, setDetailRecetas] = useState("");

  // FILTRO
  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");
  const [EstadoFiltro, setEstadoFiltro] = useState("Todos");
  const [FiltroActive, setFiltroActive] = useState(false);

  const [CheckFirma, setCheckFirma] = useState(true);
  const [CheckEnviar, setCheckEnviar] = useState(true);
  const [CheckIndicaciones, setCheckIndicaciones] = useState(true);
  const [CheckDiagnostico, setCheckDiagnostico] = useState(true);

  const [EstatusModalEnviarReceta, setEstatusModalEnviarReceta] =
    useState(false);
  const [loaderReceta, setLoaderReceta] = useState(false);

  // DIAGNOSTICOS
  const [loaderSearch, setLoaderSearch] = useState(false);
  const [PalabraClave, setPalabraClave] = useState("");
  const [ListaDiagnosticos, setListaDiagnosticos] = useState([]);
  const [DiagnosticosSeleccionados, setDiagnosticosSeleccionados] = useState([]);
  const [Diagnostico, setDiagnostico] = useState(null);
  const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);
  const [OtrosDiagnosticos, setOtrosDiagnosticos] = useState("");

  // Mensajes form ADD RECETA
  const [MensajeMedicamento, setMensajeMedicamento] = useState("true");
  const [MensajeCantidadProducto, setMensajeCantidadProducto] =
    useState("true");
  const [MensajeDescripcionUso, setMensajeDescripcionUso] = useState("true");
  const [MensajeTipoReceta, setMensajeTipoReceta] = useState("true");
  const [MensajeCedula, setMensajeCedula] = useState("true");
  const [MensajeListaMedicamentos, setMensajeListaMedicamentos] =
    useState("true");
  const [MensajeDosis, setMensajeDosis] = useState("true");

  // MESNAJE DIAGNOSTICO
  const [MensajeDiagnosticoCIIE, setMensajeDiagnosticoCIIE] = useState("true");
  const [MensajeOtrosDiagnosticos, setMensajeOtrosDiagnosticos] = useState("true");
  const [MensajePalabraClave, setMensajePalabraClave] = useState("true");

  // Mensajes Filtro
  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  async function getRecetasFilter(type) {
    setLoader(true);
    // console.log(!!FechaInicio);
    // console.log(!!FechaFin);
    if (type === 4) {
      if (!!!FechaInicio || !!!FechaFin) {
        if (!!!FechaInicio) {
          setMensajeFechaInicio("false");
        }
        if (!!!FechaFin) {
          setMensajeFechaFin("false");
        }
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("warning");
        AccionesMensaje.setMessage("Faltan campos por completar");
      } else {
        let data = new FormData();
        data.append("Tipo", EstadoFiltro);
        data.append("FechaInicio", FechaInicio);
        data.append("FechaFin", FechaFin);

        // console.log(type);

        let response = await getRecetasFilter_request(type, data);
        if (response.ok) {
          // console.log(response.data);
          // return;
          // setRecetasList(response.data);
          // setRecetasListFilter(response.data);
          // CloseFiltro(false);
          // setFiltroActive(true);
        } else {
          AccionesMensaje.setOpen(true);
          AccionesMensaje.setTypeMessage("error");
          AccionesMensaje.setMessage(response.mensaje);
        }
      }
    } else {
      if (MensajeFechaInicio === "false" || MensajeFechaFin === "false") {
        setMensajeFechaInicio("true");
        setMensajeFechaFin("true");
      }
      let data = new FormData();
      data.append("Tipo", EstadoFiltro);

      // console.log(type);

      let response = await getRecetasFilter_request(type, data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        // setRecetasList(response.data);
        // setRecetasListFilter(response.data);
        CloseFiltro(false);
        setFiltroActive(true);
      } else {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("error");
        AccionesMensaje.setMessage(response.mensaje);
      }
    }
    setLoader(false);
    return;
  }

  async function detailReceta(id) {
    // console.log(id);
    OpenModalResetasDetalle(true);
    setLoaderDetalle(true);
    let response = await getDetailRecetas_request(id);
    if (response.ok) {
      // console.log(response.data);
      setDetailRecetas(response.data);
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("error");
      AccionesMensaje.setMessage(response.mensaje);
      CloseModalResetasDetalle(true);
    }
    setLoaderDetalle(false);
    return;
  }

  async function addReceta() {
    setLoaderDetalle(true);
    if (!!Cedula && ListaMedicamentos.length > 0) {
      let diagnosticos_temp = []
      if (DiagnosticosSeleccionadosMostrados.length > 0) {
        DiagnosticosSeleccionadosMostrados.forEach(element => {
          diagnosticos_temp.push(element.diagnostico);
        });
      }
      let data = new FormData();

      data.append("Costo", CostoReceta);
      data.append("Cedula", Cedula);
      data.append("IdContacto", DatosContacto.id);
      data.append("Tipo", "0");
      data.append("Recomendaciones", Recomendaciones);
      data.append("Indicaciones", Indicaciones);
      data.append("Diagnostico", diagnosticos_temp.toString());
      data.append("Firma", CheckFirma ? 0 : 1);
      data.append("Enviar", CheckEnviar ? 0 : 1);
      data.append("MostrarIndicaciones", CheckIndicaciones ? 1 : 0);
      data.append("MostrarDiagnostico", CheckDiagnostico ? 1 : 0);
      ListaMedicamentos.forEach((element, index) => {
        data.append(`Concepto[${index}][Descripcion]`, element.Descripcion);
        data.append(`Concepto[${index}][Concepto]`, element.Concepto);
        data.append(`Concepto[${index}][Cantidad]`, element.Cantidad);
      });
      let response = await addReceta_request(data);
      if (response.ok) {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("success");
        AccionesMensaje.setMessage("Receta creada");
        cleanInputsAdd();
        getHistoria();
        AccionesModal.CloseModalNuevaReceta(true);
      } else {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("error");
        AccionesMensaje.setMessage(response.mensaje);
      }
    } else {
      if (!!!Cedula) {
        setMensajeCedula("false");
      }
      if (ListaMedicamentos.length === 0) {
        setMensajeListaMedicamentos("false");
      }

      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function getMedicamentos() {
    let response = await getMedicamentos_request();
    if (response.ok) {
      // console.log(response.data);
      let medi_temp = [];
      response.data.forEach((medi) => {
        medi_temp.push({
          Numero: medi.id,
          Opcion: `${medi.nombreActivo} - ${medi.id}`,
        });
      });
      // console.log(medi_temp);
      setMedicamentos(medi_temp);
      setMedicamentosFilter(medi_temp);
    } else {
    }
  }

  async function enviarReceta(idReceta) {
    // console.log(idReceta);
    setLoaderReceta(true);
    let response = await sendRecetas_request(idReceta);
    if (response.ok) {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("success");
      AccionesMensaje.setMessage("Receta enviada");
      detailReceta(DetailRecetas.Receta.IdReceta);
    } else {
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("error");
      AccionesMensaje.setMessage(response.mensaje);
    }
    setLoaderReceta(false);
  }

  // RECETA-MEDICAMENTOS
  function addMedicamento() {
    // console.log(NombreProducto);
    // console.log(CantidadProducto);
    // console.log(DescripcionUso);

    if (!!Medicamento && !!Dosis && !!Cantidad) {
      // setOpen(true);
      // setTypeMessage("success");
      // setMessage("Agregado");
      // console.log(Medicamento);
      let arrayMedi = Medicamento.split("-");
      let medicamento_temp = arrayMedi[0];
      let id_temp = arrayMedi[1];
      // console.log({ medicamento_temp, id_temp });
      let array = ListaMedicamentos;
      if (array.length > 0) {
        array.push({
          index: ListaMedicamentos[ListaMedicamentos.length - 1].index + 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis,
        });
      } else {
        array.push({
          index: 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis,
        });
      }
      // console.log(array);

      setListaMedicamentos(array);

      setMedicamento("");
      setCantidad(1);
      setDosis("");
      setMensajeListaMedicamentos("true");
    } else {
      if (!!!Medicamento) {
        setMensajeMedicamento("false");
      }
      if (!!!Dosis) {
        setMensajeDosis("false");
      }
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Faltan campos por completar");
    }

    return;
  }

  function eliminarMedicamento(posicion) {
    // console.log(posicion);
    let array = ListaMedicamentos;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => {
      return element.index !== posicion;
    });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        Concepto: element.Concepto,
        Cantidad: element.Cantidad,
        Descripcion: element.Descripcion,
      });
    });

    setListaMedicamentos(arrayTres);
    // console.log(arrayTres);
  }

  function cleanInputsAdd() {
    setTipoReceta(null);
    setCedula(null);
    setCostoReceta(0);
    setRecomendaciones("");
    setMedicamento("");
    setListaMedicamentos([]);
  }

  async function getCIIEDiagnosticos() {
    // let response = await getCIIEDiagnosticos(text.target.value);
    setLoaderSearch(true);
    if (!!PalabraClave) {
      let response = await getCIIEDiagnosticos_request(PalabraClave);
      // console.log(response.data);
      // return;
      if (response.ok) {
        let list = [];
        // console.log(response.data);
        if (!!response.data[0]) {
          response.data.forEach((diagnostico, index) => {
            list.push({ key: index, value: diagnostico, label: diagnostico })
          });
          setListaDiagnosticos(list);
        } else {
          setListaDiagnosticos([]);
          AccionesMensaje.setOpen(true);
          AccionesMensaje.setTypeMessage("warning");
          AccionesMensaje.setMessage("Sin resultados");
        }
      } else {
        AccionesMensaje.setOpen(true);
        AccionesMensaje.setTypeMessage("error");
        AccionesMensaje.setMessage(response.mensaje);
      }
    } else {

    }
    setLoaderSearch(false);
    return;
  }

  function addDiagnosticoCIIE() {
    if (DiagnosticosSeleccionados.length > 0) {
      DiagnosticosSeleccionados.forEach((diagnostico, index) => {
        let array = DiagnosticosSeleccionadosMostrados;
        if (array.length > 0) {
          // DiagnosticosSeleccionados.forEach(diagnostico => {
          array.push({
            index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
            diagnostico: diagnostico
          });
          // });
          setDiagnosticosSeleccionadosMostrados(array);

        } else {
          array.push({
            index: 1,
            diagnostico: diagnostico
          });

          setDiagnosticosSeleccionadosMostrados(array);
        }
      });

      // console.log(DiagnosticosSeleccionadosMostrados);

      setDiagnosticosSeleccionados([]);
    } else {
      if (DiagnosticosSeleccionados.length === 0) {
        setMensajeDiagnosticoCIIE("false");
      }
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Faltan campos por completar");

    }

    return;
  }

  function addDiagnosticoOtro() {
    if (!!OtrosDiagnosticos) {
      let array = DiagnosticosSeleccionadosMostrados;
      if (array.length > 0) {
        array.push({
          index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
          diagnostico: OtrosDiagnosticos
        });
      } else {
        array.push({
          index: 1,
          diagnostico: OtrosDiagnosticos
        });
      }

      // console.log(array);

      setDiagnosticosSeleccionadosMostrados(array);
      setOtrosDiagnosticos("");
    } else {
      if (!!!OtrosDiagnosticos) {
        setMensajeOtrosDiagnosticos("false");
      }
      AccionesMensaje.setOpen(true);
      AccionesMensaje.setTypeMessage("warning");
      AccionesMensaje.setMessage("Faltan campos por completar");

    }

    return;
  }

  function eliminarDiagnostico(posicion) {
    // console.log(posicion);
    let array = DiagnosticosSeleccionadosMostrados;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        diagnostico: element.diagnostico
      });
    });

    setDiagnosticosSeleccionadosMostrados(arrayTres);
    // console.log(arrayTres);
  }

  function filterOptions(input, option) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
  }

  function filterSort(optionA, optionB) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
  }

  const onSuggestionsFetchRequested2 = ({ value }) => {
    // console.log("Filtro RECETA");
    setMedicamentos(FiltrarMedicamentos(value));
  };

  const FiltrarMedicamentos = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLenght = inputValue.Lenght;

    var filtrado = MedicamentosFilter.filter((medicamento) => {
      var textoCompleto = medicamento.Opcion;

      if (
        textoCompleto
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(inputValue)
      ) {
        return medicamento;
      }
    });
    return inputLenght === 0 ? [] : filtrado;
  };
  const onSuggestionsClearRequested2 = () => {
    setMedicamentos([]);
  };

  const getSuggestionValueDos = (suggestion) => {
    return `${suggestion.Opcion} `;
  };
  const renderSuggestionDos = (suggestion) => (
    <div
      className="sugerencia"
      onClick={() => {
        setMedcSelectionDos(suggestion);
      }}
    >
      {/* {// console.log(suggestion)} */}
      {`${suggestion.Opcion}`}
    </div>
  );

  const onChangeDos = (e, { newValue }) => {
    if (newValue.length > 0) {
      setMensajeMedicamento("true");
    }
    // console.log(e.target);
    // console.log(newValue);
    setMedicamento(newValue);
  };

  const inputPropsDos = {
    placeholder: "Medicamento",
    value: Medicamento,
    onChange: (e, newValue) => {
      onChangeDos(e, newValue);
    },
  };

  const eventEnterDos = (e) => {
    if (e.key == "Enter") {
      // console.log(e.target.value);
      var split = e.target.value.split("-");
      // console.log(split);
      var medicamento = {
        Opcion: split[0].trim(),
        // Numero: split[1].trim(),
      };
      // console.log(medicamento);
      setMedcSelection(medicamento);
    }
  };

  // MODAL ENVIAR RECETA
  const [modal, ModalEnviarReceta] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: `¿Desea enviar la receta al paciente ${DetailRecetas.Receta.Contacto}?`,
      okText: "Enviar",
      cancelText: "Cancelar",
      onOk: () => enviarReceta(DetailRecetas.Receta.IdReceta),
      onCancel: () => setEstatusModalEnviarReceta(false),
      open: EstatusModalEnviarReceta,
      icon: <ExclamationCircleOutlined />,
      // content: (
      //   <div>
      //     <p>Paciente: {DatosCita.nombre}</p>
      //     <p>Fecha: {DatosCita.fecha}</p>
      //     <p>Hora: {DatosCita.hora}</p>
      //     <p>
      //       Tipo:{" "}
      //       {DatosCita.tipo === 0
      //         ? "Urgencia"
      //         : DatosCita.tipo === 1
      //           ? "Primera vez"
      //           : "Seguimiento"}
      //     </p>
      //     <p>Estado: {DatosCita.estado}</p>
      //   </div>
      // ),
    });
  };

  // const convertirAPDF = async (desdeURL) => {
  //   try {
  //     // Obtener el archivo desde el enlace
  //     // const response = await fetch(`https://${desdeURL}`);
  //     const response = await fetch(desdeURL);
  //     const archivoData = await response.arrayBuffer();

  //     // Crear un nuevo PDF usando pdf-lib
  //     const pdfDoc = await PDFDocument.create();
  //     const contenido = await pdfDoc.embedFont(PDFDocument.Font.Helvetica);
  //     const page = pdfDoc.addPage();
  //     const { width, height } = page.getSize();
  //     page.drawText('Archivo convertido a PDF', { x: 50, y: height - 100, font: contenido });

  //     // Generar el PDF
  //     const pdfBytes = await pdfDoc.save();

  //     // Descargar el PDF generado
  //     const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'archivo.pdf';
  //     link.click();
  //   } catch (error) {
  //     console.error('Error al convertir a PDF:', error);
  //   }
  // };

  const crearPDF = async (archivoURL) => {
    fetch(archivoURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'nombre_archivo'); // Cambia el nombre del archivo si es necesario
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.error('Error al descargar el archivo:', error));
    return;
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    page.drawText('¡Hola, este es un PDF generado desde React!', {
      x: 50,
      y: 500,
      color: rgb(0, 0, 0), // Color negro
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    window.open(window.URL.createObjectURL(blob), "_blank");
    // descargarPDF(pdfBytes)
    // this.setState({ pdfBytes });
  };

  const descargarPDF = (temp) => {
    // const { pdfBytes } = this.state;
    if (temp) {
      const blob = new Blob([temp], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'documento.pdf';
      link.click();
    }
  };

  useEffect(() => {
    getMedicamentos();
  }, []);

  return (
    <>
      {/* {loader ? (<Loader />) : (
        <RecetaTabla
          recetas={ListaRecetas}
          recetasFilter={ListaRecetasFilter}
          detailRecetas={detailReceta}
          OpenModalAddReceta={OpenModalNuevaReseta}
          filtroAcciones={
            {
              setState: [setFiltroActive, setEstadoFiltro, setFechaInicio, setFechaFin, setMensajeFechaInicio, setMensajeFechaFin],
              variables: [FiltroActive],
              acciones: [getHistoriaClinica, OpenFiltro]
            }
          }

        />
      )} */}

      <RecetasCard lista={Recetas} detailReceta={detailReceta} />

      {Recetas.length === 0 && (

        <Empty
          description={
            <span style={{ color: "black" }}>Sin recetas</span>
          }
        />
      )}

      <ModalFiltro
        isOpen={isOpenFiltro}
        closeModal={CloseFiltro}
        titulos={["Ordena tus recetas", "Hoy", "Ayer", "Semana"]}
        functions={[getHistoria, getRecetasFilter]}
        statesVariables={{
          estadoFiltro: setEstadoFiltro,
          fechaInicio: setFechaInicio,
          mensajeFechaInicio: setMensajeFechaInicio,
          fechaFin: setFechaFin,
          mensajeFechaFin: setMensajeFechaFin,
        }}
        variables={{
          estadoFiltro: EstadoFiltro,
          fechaInicio: FechaInicio,
          mensajeFechaInicio: MensajeFechaInicio,
          fechaFin: FechaFin,
          mensajeFechaFin: MensajeFechaFin,
        }}
      />

      <ModalRecetasDetalle
        optionClose={true}
        isOpen={isOpenModalResetasDetalle}
        closeModal={CloseModalResetasDetalle}
        styleDet={ResetasDetalleModal}
      >
        <div className="topCloseModalRec">
          Información de receta
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }} onClick={CloseModalResetasDetalle} />

        </div>
        <div className="TamañoRecet">
          {DetailRecetas === "" || loaderDetalle ? (
            <LoaderModals className={"TamañoRecet"} />
          ) : (
            <>
              <Row style={{ justifyContent: "space-between", padding: "1.5%" }}>
                {DetailRecetas.Receta.Enviar === 0 ? (
                  <div></div>
                ) : (
                  <>
                    {loaderReceta ? (
                      <CircularProgress size={30} sx={{ color: "#148f9f" }} />
                    ) : (
                      <ButtonAntd onClick={confirm}>
                        Enviar a paciente
                      </ButtonAntd>
                    )}
                  </>
                )}
                <Button
                  className="GeneralBoton"
                  style={{ width: "79px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  onClick={() => {
                    if (!!DetailRecetas) {
                      window.open(DetailRecetas.Receta.Url, "_blank");
                      // convertirAPDF(DetailRecetas.Receta.Url);
                      // crearPDF()
                      // crearPDF(DetailRecetas.Receta.Url);
                    }
                  }}
                >
                  Ver<PictureAsPdfIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
                </Button>
              </Row>
              <div className="DosImagenes">
                <img
                  src={
                    !!DetailRecetas.Receta.Logo
                      ? "https://" + DetailRecetas.Receta.Logo
                      : Iconos[0]
                  }
                  alt=""
                />
              </div>
              <div className="CentContentRece">
                <div className="ContentTopRece">
                  <h3 style={{ color: "#3AABEA", marginBottom: "1.5%" }}>
                    {DetailRecetas.Receta.Medico}
                  </h3>
                  <p style={{ margin: "0" }}>
                    {DetailRecetas.Receta.Profesion}
                  </p>
                  <p style={{ margin: "0" }}>
                    Cedula: {DetailRecetas.Receta.Cedula}
                  </p>
                  <p style={{ margin: "0" }}>
                    YGIA ID: {DetailRecetas.Receta.IdContacto}
                  </p>

                  <p style={{ margin: "1.5%" }}>
                    {DetailRecetas.Receta.Subtitulo}
                  </p>
                </div>
                <div className="ContentMidRec">
                  <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                    Medicamentos:
                  </p>
                  {DetailRecetas.Descripcion.map((medicamento) => (
                    <p style={{ marginLeft: "14%", marginRight: "14%" }}>
                      {medicamento.Cantidad +
                        " " +
                        medicamento.Concepto +
                        ", " +
                        medicamento.Descripcion}
                    </p>
                  ))}
                  <br />
                  <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                    Indicaciones:
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TextArea
                      autoSize
                      readOnly
                      bordered={false}
                      style={{
                        marginLeft: "10%",
                        marginRight: "10%",
                        width: "75%",
                        color: "black",
                        fontSize: "16px",
                      }}
                      value={DetailRecetas.Receta.Indicaciones}
                    // value={"No tomar alcohol\r\nTomar mucha agua\r\nTomar mucha agua"}
                    />
                  </div>
                  <p style={{ fontWeight: "500", color: "3a3a3a" }}>
                    Paciente:
                  </p>
                  <p style={{ color: "#0e67a1", fontWeight: "500" }}>
                    {" "}
                    Paciente: {DetailRecetas.Receta.Contacto}
                  </p>
                </div>
                <div className="ContentMidRec">
                  <p>Receta: {DetailRecetas.Receta.Folio}</p>
                  <p>Fecha: {DetailRecetas.Receta.Fecha}</p>
                </div>
                <div className="ContentMidRec">
                  <p>{DetailRecetas.Receta.Anexo}</p>
                </div>
                <div className="ContentMidRec">
                  <p>Firma</p>
                  {!!DetailRecetas.Receta.FirmaMedico ? (
                    <img
                      style={{ width: "30%", height: "10vh" }}
                      src={"https://" + DetailRecetas.Receta.FirmaMedico}
                      alt=""
                    />
                  ) : (
                    <p>Sin Firma</p>
                  )}
                  <p>QR Receta</p>
                  <Space direction="vertical" align="center">
                    <QRCode value={DetailRecetas.Receta.Url} size={100} />
                  </Space>
                  {/* <p>Enviar a:</p>
                  <b style={{ color: "#7d7d7d" }}>
                    {" "}
                    Nota: Seleccionar, solo si se desea compartir con otro médico
                  </b>
                  <SelectMedicos lista={MedicosList} acciones={[1, setIdMedico]} /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </ModalRecetasDetalle>

      {/* Modal nueva receta */}
      <ModalNuevaReceta
        isOpen={AccionesModal.isOpenModalNuevaReceta}
        closeModal={AccionesModal.CloseModalNuevaReceta}
        styleDet={ResetasDetalleModal}
      >
        <div className="topCloseModalRec">
          Nueva receta
          <CloseIcon
            style={{ cursor: "pointer", fontSize: 20 }}
            onClick={AccionesModal.CloseModalNuevaReceta}
          />
        </div>
        {loaderDetalle ? (

          <LoaderModals className={"TamañoRecet"} />

        ) : (
          <div className="TamañoRecet">
            <div style={{ width: "100%", padding: "1% 2%" }}>
              <Header
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 999,
                  width: "100%",
                  background: "white",
                  padding: "0 10px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckEnviar}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckEnviar(e.target.checked)
                    }}
                  >Enviar a paciente</Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckDiagnostico}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckDiagnostico(e.target.checked);
                    }}
                  >Diágnostico</Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckIndicaciones}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckIndicaciones(e.target.checked);
                    }}
                  >Indicaciones</Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckFirma}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckFirma(e.target.checked);
                    }}
                  >Firma</Checkbox>
                  {/* <Checkbox
                    defaultChecked={true}
                    checked={CheckEnviar}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckEnviar(e.target.checked);
                    }}
                  >
                    Enviar a paciente
                  </Checkbox>
                  <Checkbox
                    defaultChecked={true}
                    checked={CheckFirma}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setCheckFirma(e.target.checked);
                    }}
                  >
                    Incluir firma
                  </Checkbox> */}
                  <Button
                    className="GeneralBoton"
                    style={{
                      width: "100px",
                      color: "white",
                      marginTop: "15px",
                    }}
                    onClick={() => {
                      addReceta();
                    }}
                  >
                    <Row className="RowClasButton">
                      Guardar
                      <CheckCircleIcon fontSize="15px" />
                    </Row>
                  </Button>
                </Row>
              </Header>
              <Content
                className="site-layout"
                style={{
                  padding: "1% 10px",
                  // height: "calc(70vh - 52px)",
                  width: "100%",
                  overflow: "initial",
                  // background: "#eaeef0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "49%" }}>
                    <p style={{ margin: "0" }} htmlFor="">
                      Costo:
                    </p>
                    <InputNumber
                      // style={{ marginBottom: "2%", width:"40%" }}
                      style={{ width: "80%", height: "60%", display: "flex" }}
                      defaultValue={0}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={(e) => setCostoReceta(e)}
                    />
                  </div>
                  {/* <div style={{ width: "49%" }}>
                  <p style={{ margin: "0" }} htmlFor="">
                    Tipo:
                  </p>
                  <Select
                    placeholder="Elija tipo"
                    style={{ width: "100%" }}
                    options={[
                      { value: "0", label: "Receta" },
                      { value: "1", label: "Orden" },
                    ]}
                    value={TipoReceta}
                    onSelect={(e) => {
                      setTipoReceta(e);
                    }}
                  />
                </div> */}
                </div>
                <Campos
                  valueError={MensajeCedula}
                  textError={"Debe seleccionar una cédula"}
                  label={"Cédula profesional"}
                >
                  <Select
                    placeholder="Cédula profesional"
                    defaultValue={null}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(e) => {
                      setCedula(e);
                      setMensajeCedula("true");
                    }}
                    options={CedulasMedico}
                  />
                </Campos>

                {/* <div> */}
                <Label>Medicamento</Label>
                <div style={{ width: "100%", position: "relative" }}>
                  <Autosuggest
                    suggestions={Medicamentos}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested2}
                    onSuggestionsClearRequested={onSuggestionsClearRequested2}
                    getSuggestionValue={getSuggestionValueDos}
                    renderSuggestion={renderSuggestionDos}
                    inputProps={inputPropsDos}
                    onSuggestionSelected={eventEnterDos}
                  />
                  <LeyendaError valido={MensajeMedicamento}>
                    Debe escribir un medicamento
                  </LeyendaError>
                </div>

                <InputAntd
                  value={Dosis}
                  placeholder={"Dósis"}
                  label={"Dósis"}
                  styleDiv={{ width: "100%" }}
                  valueError={MensajeDosis}
                  setStateError={setMensajeDosis}
                  vacio={false}
                  textError={["Debe escribir una dósis"]}
                  setStateValue={setDosis}
                />

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="GeneralBoton"
                    style={{
                      width: "100px",

                    }}
                    onClick={() => {
                      if (!!Cedula) {
                        addMedicamento();
                      } else {
                        AccionesMensaje.setOpen(true);
                        AccionesMensaje.setTypeMessage("warning");
                        AccionesMensaje.setMessage(
                          "Debe seleccionar una cédula"
                        );
                      }
                    }}
                  >
                    <Row className="RowClasButton" style={{ color: "white" }}>
                      Agregar <AddCircleIcon fontSize="15px" />
                    </Row>

                  </Button>
                </div>
                <br />
                <hr />
                <br />
                <h2 style={{ marginBottom: "5%", textAlign: "center" }}>
                  <b>Medicamentos</b>
                </h2>
                <LeyendaError
                  valido={MensajeListaMedicamentos}
                  style={{
                    display:
                      MensajeListaMedicamentos === "false" ? "flex" : "none",
                  }}
                >
                  Debe agregar al menos un medicamento
                </LeyendaError>
                {ListaMedicamentos.map((medicamento, index) => (
                  <div className="ItemCard">
                    <div className="CardNotasMed">
                      <span>{`${medicamento.Concepto} - ${medicamento.Descripcion}`}</span>
                      <img
                        onClick={() => {
                          eliminarMedicamento(medicamento.index);
                        }}
                        src={Eliminar}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
                {ListaMedicamentos.length === 0 && (
                  <p style={{ marginBottom: "5%", textAlign: "center" }}>
                    Aún no se agregan medicamentos
                  </p>
                )}
                <br />

                <TextAreaAntd
                  autoSize={true}
                  label={"Indicaciones"}
                  placeholder={"Escribe algunas indicaciones"}
                  value={Indicaciones}
                  setStateValue={setIndicaciones}
                  cols="30"
                />

                <Label valido={MensajeDiagnosticoCIIE}>Diagnósticos CIE</Label>
                <div style={{ display: "flex", gap: "1", margin: "1% 0" }}>
                  <InputAntd
                    className="InputNormal"
                    type=""
                    placeholder="Palabra clave"
                    value={PalabraClave}
                    setStateValue={setPalabraClave}
                    vacio={true}
                    disabled={false}
                    style={{ width: "100%" }}
                    setStateError={setMensajePalabraClave}
                    textError={["Debe escribir una palabra clave", ""]}
                    valueError={MensajePalabraClave}
                  />
                  {loaderSearch ? (<CircularProgress size={35} sx={{ color: "#148f9f" }} />) : (
                    <Button
                      type="button"
                      style={{ margin: "0 1%", marginLeft: "1%", border: "0", background: "#148f9f", color: "white" }}
                      onClick={() => {
                        if (!!PalabraClave) {
                          getCIIEDiagnosticos();
                        } else {
                          setMensajePalabraClave("false");
                          AccionesMensaje.setOpen(true);
                          AccionesMensaje.setTypeMessage("warning");
                          AccionesMensaje.setMessage("Debe escribir una palabra");
                        }
                      }}>Buscar</Button>
                  )}
                </div>

                <div>
                  {ListaDiagnosticos.length > 0 && (
                    <>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <Select
                          value={DiagnosticosSeleccionados}
                          mode="multiple"
                          placeholder="Seleciona tus diagnósticos"
                          style={{ width: "100%" }}
                          options={ListaDiagnosticos}
                          filterOption={filterOptions}
                          filterSort={filterSort}
                          onChange={(e) => {
                            // console.log(e);
                            setDiagnosticosSeleccionados(e);
                          }}
                        />
                        <Button
                          icon={<Add style={{ color: "white" }} />}
                          style={{ background: "#148f9f" }}
                          onClick={() => {
                            addDiagnosticoCIIE();
                          }}
                        />
                      </div>
                      <LeyendaError valido={MensajeDiagnosticoCIIE}>Debe seleccionar un diagnóstico</LeyendaError>
                    </>
                  )}
                  <Label valido={MensajeOtrosDiagnosticos}>Otros diagnósticos</Label>
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <TextAreaAntd
                      value={OtrosDiagnosticos}
                      placeholder="Otros diagnósticos"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="100"
                      autoSize={true}
                      setStateValue={setOtrosDiagnosticos}
                      setStateError={setMensajeOtrosDiagnosticos}
                      valueError={MensajeOtrosDiagnosticos}
                      textError={["Debe escribir un diagnósticos"]}
                      vacio={true}
                    />
                    <Button
                      icon={<Add style={{ color: "white" }} />}
                      style={{ background: "#148f9f" }}
                      onClick={() => {
                        addDiagnosticoOtro();
                      }}
                    />
                  </div>
                </div>
                <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                  Diagnósticos
                </h3>
                {DiagnosticosSeleccionadosMostrados.length > 0 ? (
                  <>
                    {DiagnosticosSeleccionadosMostrados.map((diagnostico, index) => (
                      // <div className="ItemCard">
                      //     <div className="CardNotasMed">
                      //         <span>{diagnostico.diagnostico}</span>
                      //         <img onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                      //     </div>
                      // </div>
                      <div className="ItemCard">
                        <Row style={{ justifyContent: "space-between" }}>
                          <span>{diagnostico.diagnostico}</span>
                          <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                        </Row>
                      </div>

                    ))}
                  </>
                ) : (
                  <p style={{ textAlign: "center" }}>Aún no se agregan diagnósticos</p>
                )}
              </Content>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div className="BotonADDRece" style={{ margin: "2% 0", }} onClick={() => { addReceta(); }}>
                  <span>Guardar</span>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </ModalNuevaReceta>

      {ModalEnviarReceta}
    </>
  );
}

export default RecetasExpedientes;
