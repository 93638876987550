import { useQuery } from "@tanstack/react-query";

import { expedientes_request } from "../../services/expediente_service.js";

const QUERY_KEY = ['Expedientes'];
const fetchInterval = 60; // Minutos

const fetchData = async() => {
    const response = await expedientes_request();
    let expedientes = [];
    let success = false;

    if(response.ok){
        expedientes = response.data;
        success = true;
    }

    return {expedientes, mensaje: response.mensaje, success};
}

const useExpedientesData = () => {    
    return useQuery({
        queryFn: ()=> fetchData(), 
        queryKey: QUERY_KEY,
        refetchOnMount: true,
        refetchInterval: (fetchInterval * 60000),
    })
};

export default useExpedientesData;