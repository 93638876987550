import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Avatar, Image, Row, Select, Input } from 'antd';
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CancelRounded } from '@mui/icons-material';
import dayjs from "dayjs";
import "dayjs/locale/es";

import { showSnackbar } from "../../../redux/snackbarSlice";
import { ModalBusy, ModalScreen, HideModal } from '../../../redux/appSlice';

import LoaderModals from '../../GeneralComponents/Loader_modals';
import Iconos from '../../IconosDeSitio';
import InputAntd from '../../GeneralComponents/InputAntd';
import TextAreaAntd from '../../GeneralComponents/TextAreaAntd';
import SelectAntd from '../../GeneralComponents/SelectAntd';
import DatePickerAntd from '../../GeneralComponents/DatePickerAntd';
import Campos from '../../GeneralComponents/campos';

import { getHistoriasClinicas_request, getEstado_request, getLocalidad_request, addExpediente_request } from '../../../services/expediente_service';

import { getEdad } from '../../../utils/utils.js';

const FichaPacienteContent = () => {
  var chars = {
    "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
    "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
    "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
    "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
  }
  const [loading, setIsLoading] = useState(true);
  const [historial, setHistorial] = useState({});
  const [ficha, setFicha] = useState({});
  const [MensajeSexo, setMensajeSexo] = useState("true");
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
  const [TipoSangre, setTipoSangre] = useState("");
  const [Alergias, setAlergias] = useState("");
  const [Refiere, setRefiere] = useState("");
  const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
  const [EstadoCivil, setEstadoCivil] = useState(null);
  const [Ocupacion, setOcupacion] = useState("");
  const [Sexo, setSexo] = useState(null);
  const [Nacimiento, setNacimiento] = useState("");
  const [Edad, setEdad] = useState(0);
  const [Nacionalidad, setNacionalidad] = useState("");
  const [Estado, setEstado] = useState(null);
  const [Localidad, setLocalidad] = useState(null);
  const [NombrePareja, setNombrePareja] = useState("");
  const [EdadPareja, setEdadPareja] = useState("");
  const [OcupacionPareja, setOcupacionPareja] = useState("");
  const [NotasPareja, setNotasPareja] = useState("");
  const [EstadosList, setEstadosList] = useState([]);
  const [LocalidadesList, setLocalidadesList] = useState([]);
  const [TArterial, setTArterial] = useState("");
  const [FCardiaca, setFCardiaca] = useState("");
  const [FRespiratoria, setFRespiratoria] = useState("");
  const [Temperatura, setTemperatura] = useState("");
  const [Altura, setAltura] = useState("");
  const [Peso, setPeso] = useState("");
  const [IMC, setIMC] = useState("");
  const [Talla, setTalla] = useState("");
  const [SOxigeno, setSOxigeno] = useState("");
  const [ExploracionFisica, setExploracionFisica] = useState("");

  const dispatch = useDispatch();
  const { selectedContact } = useSelector((state) => state.contacts);

  useEffect(() => {
    const loadHostoriaClinica = async (pacienteId) => {
      await getHistoriaClinica(pacienteId);
      await getEstados();
    }

    if (selectedContact.id) {
      loadHostoriaClinica(selectedContact.id);
    }
  }, [selectedContact]);

  useEffect(() => {
    return () => {
      clearInputs();
    }
  }, [])

  const clearInputs = () => {
    setHistorial({});
    setFicha({});
    setMensajeSexo("true");
    setMensajeNacimiento("true");
    setTipoSangre("");
    setAlergias("");
    setRefiere("");
    setObservacionesPaciente("");
    setEstadoCivil(null);
    setOcupacion("");
    setSexo(null);
    setNacimiento("");
    setEdad(0);
    setNacionalidad("");
    setEstado(null);
    setLocalidad(null);
    setNombrePareja("");
    setEdadPareja("");
    setOcupacionPareja("");
    setNotasPareja("");
    setEstadosList([]);
    setLocalidadesList([]);
    setTArterial("");
    setFCardiaca("");
    setFRespiratoria("");
    setTemperatura("");
    setAltura("");
    setPeso("");
    setIMC("");
    setTalla("");
    setSOxigeno("");
  }

  async function getHistoriaClinica(idContacto) {
    let response = await getHistoriasClinicas_request(idContacto);
    console.log(response)
    if (response.ok) {
      // setDataFichaId({ historial: response.data.historial, ficha: response.data.ficha });
      setHistorial(response.data.historial);
      setFicha(response.data.ficha);

      if (response.data.historial !== null) {

        const historial = response.data.historial;

        setTipoSangre(historial.tipoSangre);
        setAlergias(historial.alergias);
        setRefiere(historial.refiere);

        setAltura(!!historial.altura ? historial.altura : "");
        setTArterial(historial.tArterial);
        setFCardiaca(historial.fCardiaca);
        setFRespiratoria(historial.fRespiratoria);
        setTemperatura(historial.temperatura);
        setSOxigeno(historial.sOxigeno);
        setPeso(historial.peso);
        setEstadoCivil(!!historial.estadoCivil ? historial.estadoCivil.toString() : null);
        setOcupacion(historial.ocupacion);
        setNombrePareja(historial.nombrePareja);
        setEdadPareja(historial.edadPareja);
        setOcupacionPareja(historial.ocupacionPareja);
        setNotasPareja(historial.nota);

        if (!!historial.peso && !!historial.altura) {
          let peso_temp = historial.peso;
          let altura_temp = historial.altura;
          let imc = peso_temp / (altura_temp * altura_temp);
          setIMC(imc.toFixed(2));
        }
      }
      if (response.data.ficha !== null) {

        let fichaId = response.data.ficha;

        setSexo(fichaId.sexo);

        if (!!fichaId.fechaNacimiento) {
          let arrayFechaTemp = fichaId.fechaNacimiento.split("-");
          let fechaTemp = `${arrayFechaTemp[2]}-${arrayFechaTemp[1]}-${arrayFechaTemp[0]}`;
          const edad = getEdad(fechaTemp);
          setEdad(edad);
          setNacimiento(fechaTemp);
        }
        setNacionalidad(fichaId.nacionalidad);
        if (!!fichaId.estado) {
          setEstado(fichaId.estado);

          // getLocalidades(fichaId.estado);
          setLocalidad(fichaId.localidad);
        }
      }
      setIsLoading(false);
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: 'error'
      }))
    }
  }

  async function getEstados() {
    let response = await getEstado_request();
    if (response.ok) {
      let estados_temp = [];
      response.data.forEach((estado) => {
        estados_temp.push({
          value: estado.id.toString(),
          label: estado.Estado,
        });
      });
      setEstadosList(estados_temp);
    } else {
      setEstadosList([]);
    }
  }

  async function addFichaContacto() {
    setIsLoading(true);
    dispatch(ModalBusy(true));
    if (!!Sexo && !!Nacimiento) {

      let data = {
        idUsuario: selectedContact.id,
        antecedentesHereditarios: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        sintomasGenerales: "",
        aparatoRespiratorio: "",
        aparatoDigestivo: "",
        aparatoCardiovascular: "",
        sistemaNefrourologico: "",
        sistemaEndocrino: "",
        sistemaHematopoyetico: "",
        sistemaNervioso: "",
        sistemaMusculo: "",
        piel: "",
        organosSentidos: "",
        esferaPsiquica: "",
        tipoSangre: TipoSangre,
        alergias: Alergias,
        observaciones: ObservacionesPaciente,
        refiere: Refiere,
        sexo: Sexo,
        nacimiento: Nacimiento,
        nacionalidad: Nacionalidad,
        estado: Estado,
        localidad: Localidad,
        cp: "",
        peso: Peso,
        altura: Altura,
        Talla: Talla,
        tArterial: TArterial,
        fCardiaca: FCardiaca,
        fRespiratoria: FRespiratoria,
        sOxigeno: SOxigeno,
        temperatura: Temperatura,
        // exploracionFisica: ExploracionFisica,
        ocupacion: Ocupacion,
        estadoCivil: EstadoCivil,
        nombrePareja: NombrePareja,
        edadPareja: EdadPareja,
        ocupacionPareja: OcupacionPareja,
        nota: NotasPareja,
      };
      console.log(data)
      let response = await addExpediente_request(data, selectedContact.id);
      console.log(response)
      if (response.ok) {
        //     cleanInputsFicha();
        //     CloseFichaContacto(true);
        //     openModalDetalle(true);
        dispatch(showSnackbar({
          show: true,
          text: 'Ficha guardada',
          type: 'success'
        }));
        dispatch(ModalBusy(false));
        dispatch(HideModal(ModalScreen.FICHA_PACIENTE));
      } else {
        dispatch(showSnackbar({
          show: true,
          text: response.mensaje,
          type: 'error'
        }));
      }
    } else {
      if (!!!Sexo) {
        setMensajeSexo("false");
      }
      if (!!!Nacimiento) {
        setMensajeNacimiento("false");
      }
      dispatch(showSnackbar({
        show: true,
        text: 'Faltan campos por completar',
        type: 'warning'
      }));
      dispatch(ModalBusy(false));
      setIsLoading(false);
    }
  }

  function filterOptions(input, option) {
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      option?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase()
        .indexOf(
          input
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase()
        ) > -1
    );
  }

  function filterSort(optionA, optionB) {
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      optionA?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase() ?? ""
    )
      .replace(expr, function (e) {
        return chars[e];
      })
      .toLowerCase()
      .localeCompare(
        (
          optionB?.label
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase() ?? ""
        )
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
      );
  }

  async function getLocalidades(estado) {
    let response = await getLocalidad_request(estado);
    console.log(response)
    if (response.ok) {
      let localidades_temp = [];
      response.data.forEach((estado) => {
        localidades_temp.push({ value: estado, label: estado });
      });
      setLocalidadesList(localidades_temp);
    } else {
      setLocalidadesList([]);
    }
  }

  return (
    <div className="ModalFichaContacto">
      {loading ? (
        <LoaderModals className={"ModalFichaContacto"} />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "1% 2%",
              width: "100%",
            }}
          >
            <Button
              type="button"
              style={{
                background: "#148f9f",
                border: "0",
                color: "white",
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
              }}
              onClick={() => {
                addFichaContacto();
              }}
            >
              Guardar
              <CheckCircleIcon fontSize="15px" />
            </Button>
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Avatar
              className="GeneralNoImage"
              size={65}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              src={
                <Image
                  src={
                    !!selectedContact.imagen
                      ? `https://${selectedContact.imagen}`
                      : Iconos[0]
                  }
                  alt=""
                />
              }
            />
          </div>


          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1%",
            }}
          >
            <b>
              {!!selectedContact.nombre ? selectedContact.nombre : "Usuario"}
            </b>
          </div>

          <div style={{ padding: "1% 2%" }}>
            {/* INFORMACION GENERAL */}
            <Accordionn>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {MensajeSexo === "false" &&
                    MensajeNacimiento === "false" && (
                      <>
                        <CancelRounded color="error" />
                        &nbsp;
                      </>
                    )}
                  Información general
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* //TODO select box? */}
                <InputAntd
                  label="Tipo de sangre:"
                  className="inputblue"
                  value={TipoSangre}
                  setStateValue={setTipoSangre}
                  placeholder="Tipo de sangre"
                  vacio={true}
                  style={{ width: "30%" }}
                  maxLength={15}
                  disabled={historial?.tipoSangre
                    ? true
                    : false
                  }
                />
                <TextAreaAntd
                  label="Alergias:"
                  value={Alergias}
                  placeholder="Alergias"
                  style={{
                    // margin: ".5% 0",
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setAlergias}
                  disabled={
                    historial?.alergias
                      ? true
                      : false
                  }
                />

                <TextAreaAntd
                  label="Quien Refiere:"
                  value={Refiere}
                  placeholder="Quien Refiere"
                  style={{
                    // margin: ".5% 0",
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setRefiere}
                  disabled={
                    historial?.refiere
                      ? true
                      : false
                  }
                />

                <Row className="rowsToCol">
                  <SelectAntd
                    label="Estado civil:"
                    value={EstadoCivil}
                    style={{ width: "100%" }}
                    placeholder="Seleccione un estado civil"
                    options={[
                      { value: "0", label: "Soltero/a" },
                      { value: "1", label: "Casado/a" },
                      { value: "2", label: "Viudo/a" },
                      { value: "3", label: "Divorciado/a" },
                      { value: "4", label: "Separado/a" },
                      { value: "5", label: "Concubinato" },
                    ]}
                    setStateValue={setEstadoCivil}
                  />
                  <InputAntd
                    className="inputblue"
                    value={Ocupacion}
                    setStateValue={setOcupacion}
                    label="Ocupación:"
                    placeholder="Escribe una ocupación"
                    vacio={true}
                  />
                </Row>
                <Row className="rowsToCol">
                  <SelectAntd
                    label="Género:"
                    value={Sexo}
                    style={{ width: "100%" }}
                    placeholder="Género"
                    options={[
                      { value: "Femenino", label: "Femenino" },
                      { value: "Masculino", label: "Masculino" },
                    ]}
                    setStateValue={setSexo}
                    setStateError={setMensajeSexo}
                    valueError={MensajeSexo}
                    textError={"Debe seleccionar un género"}
                    disabled={
                      ficha?.sexo
                        ? true
                        : false
                    }
                  />
                  <DatePickerAntd
                    label="Fecha de nacimiento:"
                    placeholder="DD/MM/AAAA"
                    style={{ width: "100%", background: "white" }}
                    value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                    setStateValue={setNacimiento}
                    setEdad={setEdad}
                    setStateError={setMensajeNacimiento}
                    valueError={MensajeNacimiento}
                    textError={["Debe elegir una fecha de nacimiento"]}
                    disabled={
                      ficha?.fechaNacimiento
                        ? true
                        : false
                    }
                  />
                </Row>
                <InputAntd
                  className="inputblue"
                  value={Nacionalidad}
                  setStateValue={setNacionalidad}
                  label="Nacionalidad:"
                  placeholder="Nacionalidad"
                  vacio={true}
                  disabled={
                    ficha?.nacionalidad
                      ? true
                      : false
                  }
                />

                <Row className="rowsToCol">
                  <Campos
                    label="Estado:"
                    children={
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                          margin: ".5% 0",
                        }}
                        placeholder="Estado"
                        optionFilterProp="children"
                        filterOption={filterOptions}
                        filterSort={filterSort}
                        value={Estado}
                        onSelect={(e) => {
                          setEstado(e);
                          getLocalidades(e);
                        }}
                        options={EstadosList}
                        disabled={
                          ficha?.estado
                            ? true
                            : false
                        }
                      />
                    }
                  />

                  <Campos
                    label="Localidad:"
                    children={
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                          margin: ".5% 0",
                        }}
                        placeholder="Localidad"
                        optionFilterProp="children"
                        filterOption={filterOptions}
                        filterSort={filterSort}
                        value={Localidad}
                        onSelect={(e) => {
                          setLocalidad(e);
                        }}
                        options={LocalidadesList}
                        disabled={
                          LocalidadesList.length === 0
                            ? true
                            : ficha?.localidad
                              ? true
                              : false
                        }
                      />
                    }
                  />
                </Row>
              </AccordionDetails>
            </Accordionn>
            {/* DATOS DE PAREJA */}
            {!historial?.NombrePareja &&
              <Accordionn>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Datos de pareja</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <InputAntd
                    label="Nombre:"
                    className="inputblue"
                    value={NombrePareja}
                    setStateValue={setNombrePareja}
                    placeholder="Escriba un nombre completo"
                    vacio={true}
                    styleDiv={{ width: "100%" }}
                  />
                  <Row className="rowsToCol">
                    <InputAntd
                      className="inputblue"
                      value={OcupacionPareja}
                      setStateValue={setOcupacionPareja}
                      label="Ocupación:"
                      placeholder="Escribe una ocupación"
                      vacio={true}
                    />

                    <InputAntd
                      label="Edad:"
                      className="inputblue"
                      value={EdadPareja}
                      setStateValue={setEdadPareja}
                      placeholder="Edad"
                      vacio={true}
                      maxLength={2}
                    />
                  </Row>
                  <TextAreaAntd
                    label="Notas/Comentarios:"
                    value={NotasPareja}
                    placeholder="Escriba una nota o comentario"
                    style={{
                      marginBottom: ".5%",
                      fontFamily: "sans-serif",
                    }}
                    columns="30"
                    autoSize={true}
                    setStateValue={setNotasPareja}
                  />
                </AccordionDetails>
              </Accordionn>
            }


            {/* EXPLORACION FISICA */}
            <Accordionn>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography> Exploración física</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="exploFisicaDetalle">
                  <InputAntd
                    label="Altura:"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    id="altura"
                    placeholder="Altura (Mtrs.)"
                    value={Altura}
                    setStateValue={setAltura}
                    vacio={true}
                    disabled={false}
                    extras={["IMC", setIMC, Peso]}
                  />

                  <InputAntd
                    label="Peso:"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    id="peso"
                    placeholder="Peso (Kg.)"
                    value={Peso}
                    setStateValue={setPeso}
                    vacio={true}
                    disabled={false}
                    extras={["IMC", setIMC, Altura]}
                  />

                  <InputAntd
                    label="IMC:"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    placeholder="IMC"
                    value={IMC}
                    setStateValue={setIMC}
                    vacio={true}
                    readOnly={true}
                  />

                  <Campos
                    label="Tipo de peso"
                    children={
                      <Input
                        style={{ width: "100%" }}
                        type=""
                        placeholder="Tipo de peso"
                        value={
                          !!IMC
                            ? IMC < 18
                              ? "Insuficiente"
                              : IMC >= 18 && IMC <= 25
                                ? "Peso normal"
                                : IMC > 25 && IMC <= 30
                                  ? "Sobrepeso"
                                  : IMC > 30 && IMC <= 40
                                    ? "Obesidad"
                                    : IMC > 40 && "Obesidad mórbida"
                            : ""
                        }
                        readOnly={true}
                        status={
                          !!IMC
                            ? IMC < 18
                              ? "warning"
                              : IMC >= 18 && IMC <= 25
                                ? ""
                                : IMC > 25 && IMC <= 30
                                  ? "warning"
                                  : IMC > 30 && IMC <= 40
                                    ? "warning"
                                    : IMC > 40 && "error"
                            : ""
                        }
                      />
                    }
                  />
                </div>
                <div className="exploFisicaDetalle">
                  <InputAntd
                    label="T. Arterial"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    placeholder="T. Arterial"
                    value={TArterial}
                    setStateValue={setTArterial}
                    vacio={true}
                    disabled={false}
                  />

                  <InputAntd
                    label="F. Cardiaca"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    placeholder="F. Cardiaca"
                    value={FCardiaca}
                    setStateValue={setFCardiaca}
                    vacio={true}
                    disabled={false}
                  />

                  <InputAntd
                    label="F. Respiratoria"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    placeholder="F. Respiratoria"
                    value={FRespiratoria}
                    setStateValue={setFRespiratoria}
                    vacio={true}
                    disabled={false}
                  />

                  <InputAntd
                    label="Temperatura"
                    style={{ width: "100%" }}
                    styleLabel={{ fontSize: "14px" }}
                    type=""
                    placeholder="Temperatura"
                    value={Temperatura}
                    setStateValue={setTemperatura}
                    vacio={true}
                    disabled={false}
                  />

                  <InputAntd
                    label="O2"
                    style={{ width: "100%" }}
                    type=""
                    placeholder="O2"
                    styleLabel={{ fontSize: "14px" }}
                    value={SOxigeno}
                    setStateValue={setSOxigeno}
                    vacio={true}
                    disabled={false}
                  />
                </div>
              </AccordionDetails>
            </Accordionn>
          </div>
        </>
      )}

    </div>
  )
}

export default FichaPacienteContent;